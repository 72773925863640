<template>
    <painel titulo="Expressos - Unidades de Atendimento" icone="building" :refreshFunction="atualizar" v-show="mostrarListagem">
        <tabela :data="unidades" dataKey="unidadeParametrosId" :globalFilterFields="['codUnidadeAtendimento', 'unidadeAtendimento']">
            <template #botoes>
                <btn-inserir></btn-inserir>
            </template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-detalhar :unidade="slotProps.data"></btn-detalhar>
                    </template>
                </Column>
                <Column field="codUnidadeAtendimento" header="Códido" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.codUnidadeAtendimento }}
                    </template>
                </Column>
                <Column field="unidadeAtendimento" header="Unidade de Atendimento" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.unidadeAtendimento }}
                    </template>
                </Column>
                <Column field="statusDescr" header="Status" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusDescr"></status>
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import UnidadesServices from './services';
import BtnDetalhar from './BtnDetalhar';
import BtnInserir from './BtnInserir';

export default {
    components: {
        BtnDetalhar,
        BtnInserir,
    },

    data() {
        return {
            unidades: null,
        };
    },

    methods: {
        obterUnidades() {
            this.$store.dispatch('addRequest');
            UnidadesServices.obterTodas().then((response) => {
                if (response?.success) {
                    this.unidades = response.data;
                } else {
                    this.unidades = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterUnidades();
        },
    },

    mounted() {
        this.obterUnidades();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Expressos_Unidades';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Expressos_Unidades') {
                if (this.$store.getters.atualizar) {
                    this.obterUnidades();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },
};
</script>
